import React from "react";
import Image from "next/image";
import { volatilityImgMap } from "@helpers/volatility_img_map";
import { Button, Card, Text } from "@atoms/index";

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
const getInitials = (name) => {
  const FirstLetter = name.charAt(0).toUpperCase();
  return FirstLetter;
};

const GroupCard = ({ data }) => {
  return (
    <div className="m-3">
      {data.map((dataItem, index) => (
        <Card className="mb-6" key={index}>
          <div className="w-full h-full flex flex-col md:flex-row md:gap-10 gap-y-4 justify-between items-center ">
            <div className="flex justify-between gap-4 items-center w-full md:w-[50%]">
              <div
                className="w-20 h-20 rounded-full flex items-center justify-center overflow-hidden"
                style={{ backgroundColor: getRandomColor() }}
              >
                <Text
                  htmlTag="p"
                  className="text-white text-lg font-bold"
                  content={getInitials(dataItem.groupName)}
                />
              </div>
              <div className="flex flex-col justify-between items-start gap-y-1">
                <Text
                  htmlTag="p"
                  content={dataItem.groupName}
                  className="text-lg md:text-xs lg:text-sm xl:text-base font-roboto md:flex"
                />
                <Text
                  htmlTag="p"
                  content={dataItem.description}
                  className="text-xs line-clamp-2 opacity-70"
                />
                <div className="flex flex-nowrap">
                  <Text
                    htmlTag="p"
                    content={`by ${dataItem.providedBy} - `}
                    className="text-sm"
                  />
                  <Text
                    htmlTag="p"
                    className="font-semibold text-sm"
                    content={dataItem.providedByID}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full md:w-[50%]">
              <div className="flex flex-col lg:gap-y-2 justify-between items-start">
                <div className="flex items-center gap-2">
                  <Image
                    src="/images/bullseye-arrow.svg"
                    width={500}
                    height={500}
                    alt="bullseye-arrow"
                    className="w-5 h-5"
                  />
                  <Text
                    htmlTag="p"
                    content={dataItem.tradingStrategy}
                    className="text-sm"
                  />
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-2">
                    <Image
                      src={`/images/${volatilityImgMap[dataItem.volatility]}`}
                      width={500}
                      height={500}
                      alt={dataItem.volatility}
                      className="w-6 h-6"
                    />
                    <Text
                      htmlTag="p"
                      content={dataItem.volatility}
                      className="text-sm"
                    />
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Image
                    src="/images/clock.svg"
                    width={500}
                    height={500}
                    alt="clock"
                    className="w-5 h-5"
                  />
                  <Text
                    htmlTag="p"
                    content={dataItem.duration}
                    className="text-sm"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between items-end gap-y-2">
                <Image
                  src="/images/star.svg"
                  width={500}
                  height={500}
                  alt="star"
                  className="w-5 h-5"
                />
                <div className="md:gap-y-2 flex-col items-end">
                  <Button
                    type="secondary"
                    children="Explore"
                    Typeof="string"
                    borderRadiusClass="rounded-md "
                    className="md:text-xl font-bold w-max md:px-5 md:py-2 border border-primary-900"
                  />
                  <Text
                    htmlTag="p"
                    content={`₹ ${dataItem.subscriptionPrice}/month`}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default GroupCard;
