import React, { useState } from "react";
import PropTypes from "prop-types";

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className="w-full flex flex-col justify-center items-start gap-y-6">
      <div className="flex w-full justify-between bg-gray-100 p-[5px] text-base md:text-lg lg:text-xl overflow-auto">
        {tabs.map((tabLabel) => (
          <div
            key={tabLabel.id}
            className={`cursor-pointer w-full p-2 rounded-md font-semibold flex items-center justify-center transition-all duration-300 whitespace-nowrap ${
              activeTab === tabLabel.id
                ? "bg-primary-900 text-gray-50"
                : "bg-transparent text-gray-500"
            }`}
            onClick={() => handleClick(tabLabel.id)}
          >
            {tabLabel.labels}
          </div>
        ))}
      </div>
      <div className="text-gray-900 h-auto overflow-auto w-full lg:w-3/5">
        {tabs.map((tabLabel) => (
          <>{activeTab === tabLabel.id && <>{tabLabel.content}</>}</>
        ))}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      labels: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    }),
  ).isRequired,
};

export default Tabs;
