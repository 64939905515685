export const isProtected = (route) => {
  if (
    route.startsWith("/adviser-directory/") ||
    route.startsWith("/learn-trading")
  ) {
    return false;
  }

  let unprotectedRoutes = [
    "/",
    "/login",
    "/contactus",
    "/aboutus",
    "/faq",
    "/terms-and-conditions",
    "/cancellation&refund",
    "/disclaimer",
    "/privacy",
    "/adviser/login",
    "/sebi-registered-adviser-directory",
  ];

  return !unprotectedRoutes.includes(route);
};
