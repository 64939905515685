import React from 'react';
import PropTypes from 'prop-types';

const EmptyLayout = ({ children, loginPage = false }) => {
  return (
    <div className="flex">
      <div className="relative w-full rounded-t-md lg:rounded-tl-md">
        {children}
      </div>
    </div>
  );
};

EmptyLayout.propTypes = {
  children: PropTypes.node,
};

export default EmptyLayout;
