import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import PropTypes from "prop-types";
import { Button } from "@atoms/index";
import { links, staticLinks } from "@helpers/content/header";
import { SideBar, StaticSideBar } from "@components/Modals";
import {
  getAuth,
  isAuthenticated,
  removeAuth,
} from "@services/identity.service";

const Header = ({ children, ...props }) => {
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showNavItemsDropdown, setShowNavItemsDropdown] = useState(null);

  const router = useRouter();

  const handleLogout = () => {
    removeAuth();
    setShowSidebar(false);
    setLoggedInUser(false);
    router.push("/");
  };

  useEffect(() => {
    const auth = getAuth();
    if (isAuthenticated(auth)) {
      setLoggedInUser(true);
    }
  }, []);

  return (
    <header className="sticky top-0 z-30 flex items-center justify-between w-full h-20 px-4 py-4 bg-white shadow-md sm:px-8 md:px-10 lg:px-16 xl:px-32 2xl:px-36 bg-blend-overlay">
      <Link
        href="/"
        children={
          <Image
            className="scale-125"
            src="/images/logo.svg"
            width={113}
            height={40}
            alt="logo"
            loading="lazy"
          />
        }
      />
      {loggedInUser ? (
        <>
          <nav className="relative flex items-center justify-evenly gap-x-2 sm:gap-x-10 md:gap-x-3 lg:gap-x-5 xl:gap-x-7">
            {links.map((item, index) =>
              item.children ? (
                <>
                  <div
                    key={index}
                    className={`${
                      showNavItemsDropdown
                        ? "text-gray-200 bg-primary-200 py-7"
                        : ""
                    } hidden font-semibold  md:text-xs lg:text-sm xl:text-base font-roboto md:flex cursor-pointer px-4 text-gray-900`}
                    onMouseEnter={() => setShowNavItemsDropdown(true)}
                  >
                    {item.content}
                  </div>
                  {showNavItemsDropdown && (
                    <ul
                      className={`absolute left-0 z-40 flex flex-col w-34 bg-white rounded top-20 shadow-4 text-left`}
                      onMouseLeave={() => setShowNavItemsDropdown(false)}
                    >
                      {item.children &&
                        item.children.map((item, index) => (
                          <div key={index}>
                            <Link
                              href={item.href}
                              onClick={() => setShowNavItemsDropdown(false)}
                            >
                              <li className="font-semibold text-gray-900 cursor-pointer md:text-xs lg:text-sm xl:text-base font-roboto p-4 hover:bg-primary-200">
                                {item.content}
                              </li>
                            </Link>
                          </div>
                        ))}
                    </ul>
                  )}
                </>
              ) : (
                <Link
                  key={index}
                  href={item.href}
                  onClick={() => setShowNavItemsDropdown(false)}
                  className="hidden font-semibold text-gray-900 md:text-xs lg:text-sm xl:text-base font-roboto md:flex px-4"
                >
                  {item.content}
                </Link>
              ),
            )}

            <Image
              className="hidden cursor-pointer md:flex "
              src="/images/user_avatar.svg"
              width={40}
              height={40}
              alt="user"
              loading="lazy"
              onClick={() => setShowDropdown(!showDropdown)}
            />

            <Image
              className="md:hidden"
              src="/images/hamburger.svg"
              width={30}
              height={30}
              alt="hamburger"
              loading="lazy"
              onClick={() => setShowSidebar(true)}
            />
            {showDropdown && (
              <ul className="absolute right-0 z-40 flex flex-col w-32 px-3 py-3 text-center bg-white rounded top-14 shadow-4">
                <li
                  onClick={handleLogout}
                  className="font-semibold text-gray-900 cursor-pointer md:text-xs lg:text-sm xl:text-base font-roboto"
                >
                  Logout
                </li>
              </ul>
            )}
          </nav>

          {showSidebar && (
            <SideBar
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
              handleLogout={handleLogout}
            />
          )}
        </>
      ) : (
        <>
          <nav className="relative flex items-center justify-evenly gap-x-3 sm:gap-x-10 md:gap-x-4 lg:gap-x-8 xl:gap-x-12">
            {staticLinks.map((item, index) =>
              item.children ? (
                <>
                  <div
                    key={index}
                    className={`${
                      showNavItemsDropdown
                        ? "text-gray-200 bg-primary-200 py-7"
                        : ""
                    } hidden font-semibold  md:text-xs lg:text-sm xl:text-base font-roboto md:flex cursor-pointer px-4 text-gray-900`}
                    onMouseEnter={() => setShowNavItemsDropdown(true)}
                  >
                    {item.content}
                  </div>
                  {showNavItemsDropdown && (
                    <ul
                      className={`absolute left-0 z-40 flex flex-col w-34 bg-white rounded top-20 shadow-4 text-left`}
                      onMouseLeave={() => setShowNavItemsDropdown(false)}
                    >
                      {item.children &&
                        item.children.map((item, index) => (
                          <div key={index}>
                            <Link
                              href={item.href}
                              onClick={() => setShowNavItemsDropdown(false)}
                            >
                              <li className="font-semibold text-gray-900 cursor-pointer md:text-xs lg:text-sm xl:text-base font-roboto p-4 hover:bg-primary-200">
                                {item.content}
                              </li>
                            </Link>
                          </div>
                        ))}
                    </ul>
                  )}
                </>
              ) : (
                <Link
                  key={index}
                  href={item.href}
                  onClick={() => setShowNavItemsDropdown(false)}
                  className="hidden font-semibold text-gray-900 md:text-xs lg:text-sm xl:text-base font-roboto md:flex"
                >
                  {item.content}
                </Link>
              ),
            )}
            <Link
              href="/login"
              className="hidden font-semibold border-0 text-primary-50 md:text-xs lg:text-sm xl:text-base md:flex font-roboto"
            >
              {" "}
              Login{" "}
            </Link>

            <Link href="/login">
              <Button
                type="primary"
                children="Get started"
                className="relative text-sm scale-90 right-2 sm:right-0 md:text-xs lg:text-sm xl:text-base md:font-medium md:scale-100 font-roboto"
              />
            </Link>
            <Image
              className="md:hidden"
              src="/images/hamburger.svg"
              width={30}
              height={30}
              alt="hamburger"
              loading="lazy"
              onClick={() => setShowSidebar(true)}
            />
          </nav>

          {showSidebar && (
            <StaticSideBar
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
            />
          )}
        </>
      )}
    </header>
  );
};

export default Header;

Header.propTypes = {
  children: PropTypes.any,
};
