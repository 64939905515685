import React, { useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import Link from "next/link";

const StaticSideBar = ({ showSidebar, setShowSidebar }) => {
  useEffect(() => {
    if (showSidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showSidebar]);

  const handleCloseModal = () => {
    setShowSidebar(false);
  };

  return (
    <div
      className={`absolute top-0 right-0 z-30 flex justify-end md:hidden w-full min-h-screen bg-primary-900 bg-opacity-20 backdrop-blur-sm ${
        showSidebar ? "block" : "hidden"
      }`}
    >
      <div className="relative z-50 flex flex-col w-2/3 min-h-screen overflow-hidden bg-white md:hidden gap-y-5 rounded-l-md shadow-2">
        <div className="flex items-center justify-between w-full px-5 py-6 bg-primary-300 ">
          {/* <Link onClick={handleCloseModal} href="/login">
            {' '}
            <Button
              type="primary"
              className="text-sm font-normal font-roboto"
              width="w-auto"
              children="Login/SignUp"
              padding="py-2 px-3"
            />
          </Link> */}
          <RxCross2
            className="w-6 h-6 font-bold text-black "
            onClick={handleCloseModal}
          />
        </div>

        <div className="flex flex-col items-start justify-start w-full pl-4 gap-y-5">
          <div className="flex flex-col w-full pl-2 gap-y-5 ">
            <Link
              onClick={handleCloseModal}
              className="text-sm font-semibold text-gray-900 "
              href="/sebi-registered-adviser-directory"
            >
              Adviser Directory
            </Link>
            <Link
              onClick={handleCloseModal}
              className="text-sm font-semibold text-gray-900 "
              href="/learn-trading"
            >
              Learn Trading
            </Link>
          </div>
          <span className="w-full h-1 pl-12 scale-y-75 bg-gray-300 opacity-70 rounded-l-md"></span>

          <div className="flex flex-col w-full pl-2 gap-y-5 ">
            <Link
              onClick={handleCloseModal}
              className="text-sm font-semibold text-gray-900 "
              href="/aboutus"
            >
              About us
            </Link>
            <Link
              onClick={handleCloseModal}
              className="text-sm font-semibold text-gray-900 "
              href="/contactus"
            >
              Contact us
            </Link>
            <Link
              onClick={handleCloseModal}
              className="text-sm font-semibold text-gray-900 "
              href="/faq"
            >
              FAQs
            </Link>
          </div>
          <span className="w-full h-1 pl-12 scale-y-75 bg-gray-300 opacity-70 rounded-l-md"></span>

          <div className="flex flex-col w-full pl-2 ">
            <Link
              onClick={handleCloseModal}
              className="text-sm font-semibold text-gray-900 "
              href="/contactus"
            >
              Help and Support
            </Link>
          </div>
          <span className="w-full h-1 pl-12 scale-y-75 bg-gray-300 opacity-70 rounded-l-md"></span>
        </div>
      </div>
    </div>
  );
};

export default StaticSideBar;
