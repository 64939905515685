import "../styles/globals.css";
import { getLayoutConfig } from "@components/Layout/LayoutConfiguration";
import WithAuth from "@components/Auth/WithAuth";

export default function App({ Component, pageProps, router }) {
  let layoutConfig = getLayoutConfig(router.pathname);
  const { Layout: FetchedLayout, overrides } = layoutConfig;

  return (
    <WithAuth router={router}>
      <FetchedLayout children={<Component {...pageProps} />} {...overrides} />
    </WithAuth>
  );
}
