import React from 'react';
import PropTypes from 'prop-types';
import { Header, Footer } from '@molecules/index';

const Layout = ({ children }) => {
  return (
    <div className="flex">
      <div className="relative w-full rounded-t-md lg:rounded-tl-md">
        <Header />
        {children}
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
