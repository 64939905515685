import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getAuth, isAuthenticated } from '@services/identity.service';
import { isProtected } from './Security';

const WithAuth = ({ children }) => {
  const router = useRouter();
  const auth = getAuth();

  useEffect(() => {
    if (!isAuthenticated(auth) && isProtected(router.pathname)) {
      router.replace('/');
    }
  }, [auth, router]);

  return children;
};

export default WithAuth;
