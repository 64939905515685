import React from 'react';
import PropTypes from 'prop-types';

const Link = ({ weight, size, children, href, ...property }) => {
  return (
    <a
      href={href}
      onClick={property.onClick}
      className={`${size ? size : 'text-base'} ${
        weight ? weight : 'font-medium'
      } ${property.className} `}
    >
      {children}
    </a>
  );
};

export default Link;

Link.prototype = {
  weight: PropTypes.string,
  size: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.any,
};
