import EmptyLayout from "./EmptyLayout";
import Layout from "./Layout";
import AdviserLayout from "./AdviserLayout";

const LayoutConfig = [
  {
    path: "/login",
    Layout: EmptyLayout,
  },
  {
    path: "/profile",
    Layout: EmptyLayout,
  },
  {
    path: "/home",
    Layout: Layout,
  },
  {
    path: "/adviser/login",
    Layout: EmptyLayout,
  },
  {
    path: "/adviser/dashboard",
    Layout: AdviserLayout,
  },
];

const getLayoutConfig = (path) => {
  const config = LayoutConfig.find((configItem) =>
    path.includes(configItem.path),
  );

  return config || { Layout };
};

export { getLayoutConfig };
