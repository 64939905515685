import React from "react";
import Image from "next/image";
import { Button, Card, Text } from "@atoms/index";

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
const getInitials = (name) => {
  const FirstLetter = name.charAt(0).toUpperCase();
  return FirstLetter;
};

const AdviserCard = ({ data }) => {
  return (
    <div className="m-3">
      {data.map((dataItem, index) => (
        <Card className="mb-6" key={index}>
          <div className="w-full h-full flex flex-col md:flex-row md:gap-10 gap-y-4 justify-between items-center ">
            <div className="flex justify-between gap-4 items-center w-full md:w-[50%]">
              <div
                className="w-20 h-20 rounded-full flex items-center justify-center overflow-hidden"
                style={{ backgroundColor: getRandomColor() }}
              >
                <Text
                  htmlTag="p"
                  className="text-white text-lg font-bold"
                  content={getInitials(dataItem.adviserName)}
                />
              </div>
              <div className="flex flex-col justify-between items-start gap-y-1">
                <Text
                  htmlTag="p"
                  className="text-lg md:text-xs lg:text-sm xl:text-base font-roboto md:flex"
                  content={dataItem.adviserName}
                />
                <Text
                  htmlTag="p"
                  className="text-xs line-clamp-2 opacity-70 "
                  content={dataItem.description}
                />
                <Text
                  htmlTag="p"
                  content={dataItem.adviserID}
                  className="font-medium text-sm"
                />
              </div>
            </div>
            <div className="flex justify-between w-full md:w-[50%]">
              <div className="flex flex-col gap-y-1 md:gap-y-2 justify-between items-start">
                <div className="flex gap-2">
                  <Image
                    src="public/images/adviser_experience.svg"
                    width={500}
                    height={500}
                    alt="adviser_experience"
                    className="w-5 h-5"
                  />
                  <Text
                    htmlTag="p"
                    content={`${dataItem.experience} + years`}
                    className="text-sm"
                  />
                </div>
                <div className="flex gap-2">
                  <Image
                    src="public/images/top_adviser_duration.svg"
                    width={500}
                    height={500}
                    alt="top_adviser_duration"
                    className="w-5 h-5"
                  />
                  <Text
                    htmlTag="p"
                    content={dataItem.duration}
                    className="text-sm"
                  />
                </div>
                <div className="flex gap-2">
                  <Image
                    src="public/images/top_adviser_location.svg"
                    width={500}
                    height={500}
                    alt="top_adviser_location"
                    className="w-5 h-5"
                  />
                  <Text
                    htmlTag="p"
                    content={dataItem.location}
                    className="flex flex-nowrap text-sm"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between items-end gap-y-6">
                <Image
                  src="public/images/star.svg"
                  width={500}
                  height={500}
                  alt="star"
                  className="w-5 h-5"
                />
                <Button
                  type="secondary"
                  children="Explore"
                  Typeof="string"
                  borderRadiusClass="rounded-md "
                  className="text-xl font-bold border border-primary-900 text-primary-50"
                />
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default AdviserCard;
