import PropTypes from "prop-types";

const Button = ({
  type,
  Typeof = "button",
  children,
  disabled,
  onClick,
  borderRadiusClass = "rounded-lg",
  wrapperClass = "",
  ...property
}) => {
  const widthClass = property.width ? property.width : "w-full";
  const paddingClass = property.padding || "px-4 py-2";

  return (
    <div className={`${wrapperClass}`}>
      {(() => {
        if (type === "primary") {
          return (
            <button
              onClick={onClick}
              type={Typeof}
              disabled={disabled}
              className={`relative ${paddingClass} ${widthClass} flex justify-center items-center 
                        ${
                          property.textcolor ? property.textcolor : "text-white"
                        }  ${borderRadiusClass} 
                        ${property.color ? property.color : "bg-primary-900"}  
                        ${property.className} ${disabled ? "opacity-50" : ""} 
                        ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
            >
              {children}
            </button>
          );
        }
        if (type === "secondary") {
          return (
            <button
              disabled={disabled}
              onClick={onClick}
              type={Typeof}
              className={` relative ${paddingClass} ${widthClass} flex ${borderRadiusClass}
                        justify-center items-center ${property.color}
                        ${
                          property.textcolor ? property.textcolor : "text-black"
                        }
                        ${property.className} ${disabled ? "opacity-50" : ""} 
                        ${disabled ? "cursor-not-allowed" : "cursor-pointer"} `}
            >
              {children}
            </button>
          );
        }
      })()}
    </div>
  );
};

export default Button;

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
