import React from 'react';
import PropTypes from 'prop-types';

const Text = ({
  weight,
  size,
  content,
  isInnerHtml = false,
  htmlTag = 'div',
  fontfamily,
  ...property
}) => {
  const fontWeightClass = weight || 'font-normal';
  const fontSizeClass = size || 'text-base';
  const fontColorClass = property.color || 'text-black';
  const fontFamily = fontfamily || 'font-roboto';
  const className =
    ` ${fontColorClass} ${fontFamily} ${fontWeightClass} ${fontSizeClass} ${
      property.className || ''
    } `.trim();

  const contentTag = React.createElement(
    htmlTag,
    {
      className: className,
      ...(isInnerHtml && { dangerouslySetInnerHTML: { __html: content } }),
    },
    content
  );

  return contentTag;
};

export default Text;

Text.propTypes = {
  weight: PropTypes.string,
  size: PropTypes.string,
  content: PropTypes.string,
  htmlTag: PropTypes.string,
};
