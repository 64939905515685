import React, { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { Text } from "@atoms/index";
import { phoneNumber, email } from "@helpers/constants";
import {
  socialMediaLinks,
  adviserSections,
  disclaimerContent,
} from "@helpers/content/footer";

const WebLinks = ({ title, links }) => (
  <span className="flex flex-col text-xs text-gray-200 sm:text-sm md:text-lg gap-y-1 md:gap-y-2">
    <Text
      htmlTag="p"
      className="mb-1 md:tracking-wider"
      weight="font-semibold"
      size=" text-sm sm:text-base md:text-xl"
      color="text-white"
      content={title}
    />
    <ul className="flex flex-col cursor-pointer gap-y-1 md:gap-y-2 font-extralight">
      {links.map((item, index) => (
        <li key={index}>
          <Link href={item.href}>{item.text}</Link>
        </li>
      ))}
    </ul>
  </span>
);

const AdviserFooter = () => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const getContactValue = (type) => {
    switch (type) {
      case "phone":
        return `tel:${phoneNumber}`;
      case "email":
        return window.open(
          `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${email}`,
          "_blank",
        );
      default:
        break;
    }
  };

  return (
    <>
      <div className="relative flex flex-col items-center justify-start w-full px-4 py-8 sm:px-8 md:px-10 lg:px-16 xl:px-32 2xl:px-36 bg-primary-900 gap-y-10">
        <div className="relative flex items-start justify-between w-full gap-x-10">
          <div className="relative flex flex-col w-auto gap-y-4 md:gap-y-7">
            <Image
              src="/images/BTST_1.svg"
              width={113}
              height={40}
              alt="logo"
              loading="lazy"
              className="origin-left sm:scale-110 md:scale-125"
            />

            <span className="flex justify-start w-auto pl-2 origin-left scale-75 md:scale-100 gap-x-2 md:gap-x-5">
              {socialMediaLinks.map((link, index) => (
                <a href={link.href} target="_blank">
                  <span
                    key={index}
                    className="flex items-center justify-center w-6 h-6 p-px bg-white rounded-full cursor-pointer md:p-1 "
                  >
                    <Image
                      src={link.src}
                      width={15}
                      height={15}
                      alt={link.alt}
                    />
                  </span>
                </a>
              ))}
            </span>

            <span className="relative flex w-auto ml-2 ">
              <Text
                htmlTag="div"
                weight="font-medium"
                size=" text-sm md:text-xl"
                color="text-white"
                content="Contact Us"
              />
            </span>

            <div className="relative flex flex-col bottom-2 gap-y-px md:gap-y-3 ">
              <span
                onClick={() => getContactValue("phone")}
                className="relative cursor-pointer "
              >
                <Text
                  htmlTag="p"
                  weight="font-normal"
                  size=" text-xxs sm:text-sm md:text-base "
                  color="text-white"
                  className="ml-2 "
                  content="+91- 8130576690"
                />
              </span>

              <span
                onClick={() => getContactValue("email")}
                className="relative cursor-pointer "
              >
                <Text
                  htmlTag="p"
                  weight="font-normal"
                  size=" text-xxs sm:text-sm md:text-base "
                  color="text-white"
                  className="ml-2 "
                  content="btstsolutions@gmail.com"
                />
              </span>
            </div>
          </div>

          <div className="relative flex flex-grow w-full py-1 pl-2 md:pl-0 justify-evenly gap-x-5 sm:gap-x-10 sm:justify-end lg:gap-x-28 md:w-2/4 md:px-0">
            {adviserSections.map((section, index) => (
              <WebLinks
                key={index}
                title={section.title}
                links={section.links}
              />
            ))}
          </div>
        </div>

        <div className="relative justify-start hidden w-full text-left md:px-0 md:flex-col gap-y-4 md:flex">
          <Text
            htmlTag="h1"
            weight="font-medium"
            size="text-lg"
            color="text-gray-200"
            content="Disclaimer :"
          />
          {disclaimerContent.map((item, index) => (
            <Text
              key={index}
              htmlTag="p"
              weight="font-normal"
              size="text-base"
              color="text-gray-200"
              content={item.content}
            />
          ))}
        </div>

        <div className="relative flex items-center justify-center w-full">
          <Text
            htmlTag="p"
            weight="font-normal"
            size="text-xs md:text-base"
            color="text-gray-300"
            content="© 2023, BTST Technologies Pvt. Ltd. All rights reserved."
          />
        </div>
      </div>

      <div className="w-full bg-gray-300 px-9 md:hidden">
        {!showDisclaimer && (
          <div className="flex items-center justify-between w-full py-3 ">
            <Text
              htmlTag="h1"
              weight="font-medium"
              size="text-base"
              content="More about BTST"
            />
            <span
              className="text-2xl font-medium text-black cursor-pointer "
              onClick={() => setShowDisclaimer(true)}
            >
              +
            </span>
          </div>
        )}

        {showDisclaimer && (
          <div>
            <div className="flex items-center justify-between w-full py-3 ">
              <Text
                htmlTag="h1"
                weight="font-medium"
                size="text-lg"
                content="Disclaimer:"
              />
              <span
                className="text-2xl font-medium text-black cursor-pointer "
                onClick={() => setShowDisclaimer(false)}
              >
                -
              </span>
            </div>
            <div className="relative flex flex-col justify-start w-full py-4 text-justify gap-y-4 ">
              {disclaimerContent.map((item, index) => (
                <Text
                  key={index}
                  htmlTag="p"
                  weight="font-normal"
                  size="text-sm"
                  color="text-black"
                  content={item.content}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdviserFooter;
