import PropTypes from "prop-types";

const Card = ({ backgroundColor, children, ...property }) => {
  const className = `card items-center relative overflow-hidden shadow-md shadow-t-md rounded-lg p-5 ${
    backgroundColor ? backgroundColor : "bg-white"
  } ${property.width ? property.width : "w-full"} ${property.className}`;

  return (
    <div className={className}>
      <div className="space-y-4">{children}</div>
    </div>
  );
};

export default Card;

Card.prototype = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
};
