export const staticLinks = [
  {
    content: "Adviser Directory",
    href: "/sebi-registered-adviser-directory",
  },
  {
    content: "Learn Trading",
    href: "/learn-trading",
  },
  {
    content: "About Us",
    href: "/aboutus",
  },
  {
    content: "Contact Us",
    href: "/contactus",
  },
];

export const links = [
  {
    content: "Adviser Directory",
    href: "/adviser-directory",
  },
  {
    content: "Learn Trading",
    href: "/learn-trading",
  },
  {
    content: "About Us",
    href: "/aboutus",
  },
  {
    content: "Contact Us",
    href: "/contactus",
  },
];
