import PropTypes from 'prop-types';

const Input = ({
  type,
  register,
  placeholder,
  label,
  onChange,
  onClick,
  error,
  message,
  checked,
  value,
  id,
  ...property
}) => {
  return (
    <div>
      {label && (
        <label
          className={` ml-1 relative bottom-3 w-full text-left text-gray-700 ${property.labelclass}`}
        >
          {label}
        </label>
      )}

      <div className="flex items-start justify-start -space-x-1">
        {type === 'tel' && (
          <span
            className={` ${
              property.height || 'h-12'
            } py-3 px-2 border-2 border-gray-300 rounded-l-md font-medium  `}
          >
            +91
          </span>
        )}
        <input
          {...(register ? register(id) : {})}
          id={id}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          onClick={onClick}
          checked={checked}
          value={value}
          {...property}
          className={` border-2 ${error ? 'border-red-600' : 'border-gray-300'} 
                ${property.width || 'w-full'}
                ${property.height || 'h-12'} focus:outline-none px-4 py-3 ${
            type !== 'tel' ? 'rounded-md' : 'rounded-r-md '
          }   ${property.className}  `}
        />
      </div>

      {error ? (
        <div className="mt-2 ml-1 text-xs font-medium text-left text-red-600">
          {error.message}
        </div>
      ) : (
        <div
          className={` ${
            message ? 'flex' : 'hidden'
          } mt-2 ml-1 text-xs font-medium text-left text-gray-500`}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default Input;

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  message: PropTypes.string,
  error: PropTypes.object,
  register: PropTypes.any,
};
