import Cookies from "universal-cookie";
const jwt = require("jsonwebtoken");

const cookies = new Cookies();
const JWT_SECRET_KEY = process.env.NEXT_PUBLIC_JWT_SECRET;

export const setAuth = (user) => {
  cookies.set("AUTH", JSON.stringify(user), {
    path: "/",
    maxAge: 24 * 60 * 60,
    sameSite: "strict",
  });
};

export const removeAuth = () => {
  cookies.remove("AUTH", {
    path: "/",
    sameSite: "strict",
  });
};

export const getAuth = () => {
  return cookies.get("AUTH");
};

export const isAuthenticated = (user) => {
  if (user && user.token) {
    try {
      const decode = jwt.verify(user.token, JWT_SECRET_KEY);
      return true;
    } catch (error) {
      return false;
    }
  }
  return false;
};

export const isUnauthorizedRequest = (auth) => {
  return !auth || !isAuthenticated(JSON.parse(auth));
};
