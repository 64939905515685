import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

const Dropdown = ({
  options,
  defaultValue,
  placeholder,
  onChange,
  menuPlacement,
}) => {
  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
      menuPlacement={menuPlacement}
      className="w-full h-10 border border-gray-300 rounded-md xl:h-12"
      styles={{
        control: (provided) => ({
          ...provided,
          height: '100%',
          outline: 'none',
          paddingLeft: '8px',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#9C9CAF',
        }),
      }}
    />
  );
};

export default Dropdown;

Dropdown.prototype = {
  options: PropTypes.Array,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  menuPlacement: PropTypes.string,
};
