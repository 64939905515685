export const quickLinks = [
  { text: "Disclaimer", href: "/disclaimer" },
  { text: "Privacy Policy", href: "/privacy" },
  { text: "Terms of use", href: "/terms-and-conditions" },
  { text: "Refund Policy", href: "/cancellation&refund" },
];

export const btstLinks = [
  { text: "About Us", href: "/aboutus" },
  { text: "Contact Us", href: "/contactus" },
  { text: "FAQs", href: "/faq" },
];

export const socialMediaLinks = [
  {
    src: "/images/twitter.svg",
    alt: "Twitter",
    href: "https://twitter.com/btst_tech",
  },
  {
    src: "/images/telegram.svg",
    alt: "Telegram",
    href: "https://t.me/btst_tech",
  },
  {
    src: "/images/linkedln.svg",
    alt: "LinkedIn",
    href: "https://www.linkedin.com/company/btst_tech/",
  },
];

export const sections = [
  { title: "BTST", links: btstLinks },
  { title: "Quick Links", links: quickLinks },
];

export const adviserSections = [
  { title: "BTST", links: btstLinks.slice(0, 3) },
  { title: "Quick Links", links: quickLinks.slice(0, 3) },
];

export const disclaimerContent = [
  {
    content:
      "Trading and investing in the stock market involves inherent risks, and past performance does not guarantee future results. The value of investments can fluctuate, and clients may experience gains or losses. Clients are solely responsible for their investment decisions, and BTST shall not be liable for any losses or damages incurred as a result of using our platform or following advice from advisers.",
  },
];
