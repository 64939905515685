import React, { useState } from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import { useRouter } from "next/router";
import { Link } from "@atoms/index";
import { AdviserFooter } from "@molecules/index";
import { removeAuth } from "@services/identity.service";

const AdviserLayout = ({ children }) => {
  const router = useRouter();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleLogout = () => {
    removeAuth();
    router.push("/");
  };

  return (
    <div>
      <div className="sticky top-0 z-30 flex items-center justify-between w-full h-20 px-4 py-4 bg-white shadow-md sm:px-8 md:px-10 lg:px-16 xl:px-32 2xl:px-36 bg-blend-overlay">
        <Link
          href="/"
          children={
            <Image
              className="scale-125"
              src="/images/logo.svg"
              width={113}
              height={40}
              alt="logo"
              loading="lazy"
            />
          }
        />
        <Image
          className="cursor-pointer"
          src="/images/user_avatar.svg"
          width={40}
          height={40}
          alt="user"
          loading="lazy"
          onClick={() => setShowDropdown(!showDropdown)}
        />
        {showDropdown && (
          <ul className="absolute right-0 z-40 flex flex-col w-32 px-3 py-3 text-center bg-white rounded top-14 shadow-4">
            <li
              onClick={handleLogout}
              className="font-semibold text-gray-900 cursor-pointer md:text-xs lg:text-sm xl:text-base font-roboto"
            >
              Logout
            </li>
          </ul>
        )}
      </div>
      {children}
      <AdviserFooter />
    </div>
  );
};

AdviserLayout.propTypes = {
  children: PropTypes.node,
};

export default AdviserLayout;
