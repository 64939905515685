import React from "react";
import Text from "@atoms/Text";
import Image from "next/image";
import Button from "@atoms/Button";
import Card from "@atoms/Card";

const TradeCard = ({ data }) => {
  return (
    <div className="m-3">
      {data.map((dataItem) => (
        <Card key={dataItem.id} className="mb-6">
          <div className="w-full h-full flex gap-10 justify-between">
            <div className="w-full flex flex-col items-start justify-between gap-y-3">
              <Text
                className="tracking-tight"
                htmlTag="h3"
                weight="font-medium"
                size="text-lg md:text-xl"
                color="text-black"
                content={dataItem.strategy}
              />
              <div className="flex w-full flex-wrap justify-between items-center">
                <div className="flex gap-x-1">
                  <Image
                    src="/images/up_green_graph.svg"
                    width={500}
                    height={500}
                    alt="up_green_graph"
                    className="w-5 h-5"
                  />
                  <Text htmlTag="p" content="Upside" />
                  <Text htmlTag="p" content={`${dataItem.updatePercentage}%`} />
                </div>
                <div className="flex gap-x-1">
                  <Image
                    src="/images/down_red_graph.svg"
                    width={500}
                    height={500}
                    alt="down_red_graph"
                    className="w-5 h-5"
                  />
                  <Text htmlTag="p" content="Stop Loss" />
                  <Text htmlTag="p" content={`₹ ${dataItem.price}`} />
                </div>
                <div className="flex gap-x-1">
                  <Image
                    src="/images/clock.svg"
                    width={500}
                    height={500}
                    alt="clock"
                    className="w-5 h-5"
                  />
                  <Text htmlTag="p" content={dataItem.days} />
                  <Text htmlTag="p" content="Days" />
                </div>
                <div className="flex gap-x-1">
                  <Text htmlTag="p" content="R/R" />
                  <Text htmlTag="p" content="Ratio" />
                  <Text htmlTag="p" content={dataItem.riskRewardRatio} />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-between">
              <Text htmlTag="p" content={`₹ ${dataItem.price}`} />
              <Button
                type="secondary"
                children="Unlock"
                Typeof="string"
                borderRadiusClass="rounded-md "
                className="text-lg font-bold w-max border border-primary-900"
              />
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default TradeCard;
