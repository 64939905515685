import React, { useEffect } from "react";
import { Text, Button } from "@atoms/index";
import Image from "next/image";
import Link from "next/link";

const FaqQuery = ({ showQueryModal, setShowQueryModal }) => {
  useEffect(() => {
    if (showQueryModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showQueryModal]);

  return (
    <div
      onClick={() => setShowQueryModal(false)}
      className={`absolute top-0 right-0 z-40 flex items-center justify-center w-full min-h-screen bg-black md:hidden bg-opacity-30 ${
        showQueryModal ? "block" : "hidden"
      }`}
    >
      <div
        onClick={(event) => event.stopPropagation()}
        className="bg-white flex text-center flex-col gap-y-5 z-50 w-4/5 items-center p-5 rounded-md  shadow-[1px_2px_13px_8px_#00000024]"
      >
        <Text
          htmlTag="h1"
          size="text-lg"
          weight="font-semibold"
          color="text-primary-50"
          content="Didn’t find an answer?"
        />
        <Image
          src={"/images/faqvector.svg"}
          width={200}
          height={100}
          alt="faqvector"
          loading="lazy"
        />
        <Text
          htmlTag="p"
          size="text-xs"
          weight="font-semibold"
          content="Don’t worry! Our dedicated team will help resolve your query"
        />
        <Link href="/contactus" prefetch={true}>
          {" "}
          <Button
            type="secondary"
            children="ASK YOUR QUERY"
            Typeof="button"
            borderRadiusClass="rounded-md"
            textcolor="text-primary-50"
            className="text-sm font-semibold border border-primary-900 font-roboto"
          />{" "}
        </Link>
      </div>
    </div>
  );
};

export default FaqQuery;
