const Modal = ({
  show,
  onClose,
  label,
  width,
  height,
  hideTitle,
  children,
  labelSize,
  ...property
}) => {
  const yScroll = height ? `overflow-y-auto ${height}` : "";
  const lableFontSize = labelSize ? labelSize : "text-lg md:text-xl";
  return (
    <>
      <div
        className={`fixed top-0 left-0 flex items-center justify-center w-full h-full modal overflow-y-auto z-50 ${
          !show && "hidden"
        }`}
      >
        <div className="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>

        <div
          className={`text-black bg-white w-full mx-4 md:mx-0 ${width}  rounded-lg shadow-lg z-50 overflow-y-auto`}
        >
          <div className="flex items-center justify-between p-5 border-b">
            <p className={`font-bold text-gray-800  ${lableFontSize}`}>
              {label}
            </p>

            <div className="z-50 cursor-pointer modal-close">
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={onClose}
              >
                <img src="/images/close.svg" />
              </button>
            </div>
          </div>

          <div className={`p-6 ${property.className} ${yScroll}`}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
